import RestService from "model/service/dataStorage/RestService";
import IRestServiceOptions from "../../interface/api/IRestServiceOptions";
import IRestServiceCollectionResponse from "../../interface/api/IRestServiceCollectionResponse";
import IView from "../../interface/dataStorage/IView";
import Utils from "../../../utils";
import IViewAction from "../../interface/dataStorage/view/IViewAction";

interface IRestViewsServiceCollectionResponse extends IRestServiceCollectionResponse {
    results: Array<IView>
}

interface IRestViewsService {
    collectionList(options?: IRestServiceOptions): Promise<IRestViewsServiceCollectionResponse>,

    collectionCreate(data: any): Promise<IView>,

    resourceRetrieve(id: number | string): Promise<IView>,

    resourceUpdate(id: number | string, data: any): Promise<IView>,

    resourceDelete(id: number | string): Promise<void>,

    fetchPermissions: (id: number | string, filters: any) => Promise<{
        permissions: { [className: string]: { [actionUuid: string]: boolean } }
    }>

    clone(view: IView): IView
}

const ViewsService_COLLECTION = 'views'

const ViewsService: IRestViewsService = {
    collectionList: function (options?) {
        return RestService.collectionList(ViewsService_COLLECTION, options as unknown as IRestServiceOptions) as Promise<IRestViewsServiceCollectionResponse>
    },
    collectionCreate: function (data) {
        return RestService.collectionCreate(ViewsService_COLLECTION, data) as Promise<IView>
    },
    resourceRetrieve: function (id) {
        return RestService.resourceRetrieve(ViewsService_COLLECTION, id) as Promise<IView>
    },
    resourceDelete: function (id) {
        return RestService.resourceDelete(ViewsService_COLLECTION, id)
    },
    resourceUpdate: function (id, data) {
        return RestService.resourceUpdate(ViewsService_COLLECTION, id, data) as Promise<IView>
    },
    fetchPermissions: function (id: number | string, filters: any) {
        return RestService.resourceUpdate('view-permissions', id, filters) as Promise<{
            permissions: { [className: string]: { [actionUuid: string]: boolean } }
        }>
    },
    clone(view: IView): IView {
        const uuid = Utils.uuid()
        const unsetIds = (s: IViewAction, unit: string) => ({...s, uuid: undefined, id: null, unit})
        return {
            ...view,
            id: 0,
            uuid: uuid,
            route: undefined,
            name: view.name + "_copy",
            label: view.label + " (copy)",
            units: view.units.map(unit => {
                const unitUuid = Utils.uuid()
                return ({
                    ...unit,
                    view: uuid,
                    uuid: unitUuid,
                    id: 0,
                    schemaActions: unit.schemaActions.map(s => unsetIds(s, unitUuid)),
                    collectionActions: unit.collectionActions.map(s => unsetIds(s, unitUuid)),
                    entryActions: unit.entryActions.map(s => unsetIds(s, unitUuid)),
                    items: unit.items.map(s => ({...s, uuid: Utils.uuid(), id: undefined, unit: unitUuid}))
                })
            }),
        }
    }
}

export default ViewsService