import React, {RefObject} from "react";
import {Button, Form, FormInstance, Mentions, Row} from "antd";
import RoutePicker from "../../../../shared/pickers/RoutePicker";
import {ICardStepProps} from "./CardModal";
import {RightOutlined} from "@ant-design/icons";
import IContentType from "../../../../../model/interface/dataStorage/IContentType";
import ICard, {CARD_RESERVED_NAMES} from "../../../../../model/interface/dataStorage/ICard";
import ActionPicker from "../../../../shared/pickers/ActionPicker";
import {connect} from "react-redux";
import selectors from "../../../../../redux/selectors";
import {IAppState} from "../../../../../redux/store";
import IRepositoryService from "../../../../../model/interface/IRepositoryService";

interface IState {
    formValues: ICard,
    changedCards: ICard[]
}

interface IProps extends ICardStepProps {
    contentType: IContentType
    findOneByFullClassName: (className: string) => IRepositoryService
}

class CardProperties extends React.Component<IProps, IState> {

    formRef: RefObject<FormInstance> = React.createRef()

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            formValues: {...props.card},
            changedCards: props.contentType.cards.filter(c => c.uuid !== props.card.uuid)
        }
    }

    confirm = (back?: boolean) => {
        const {card, onChange, setValid} = this.props
        const {formValues, changedCards} = this.state
        this.formRef.current?.validateFields().then(values => {
            setValid(true)
            onChange({...card, ...values, ...formValues}, back, changedCards)
        }).catch(() => setValid(false))
    }

    onValuesChange = (formValues: ICard) => {
        this.setState(state => ({formValues: {...state.formValues, ...formValues}}), this.confirm)
    }

    getService() {
        const {findOneByFullClassName, contentType} = this.props
        return findOneByFullClassName(contentType.fullClassName);
    }

    render() {
        const {card, contentType} = this.props

        return (
            <Row className={'flex-column h-100'} justify={"space-between"}>
                <Form
                    onValuesChange={this.onValuesChange}
                    initialValues={card ? card : {}}
                    ref={this.formRef}
                >
                    <Form.Item
                        label={"Systémový Název"}
                        name={"name"}
                        rules={[{required: true}]}
                    >
                        <Mentions
                            split={''}
                            placeholder={"název nebo # pro vyhrazený název"}
                            prefix={'#'}
                        >
                            {Object.entries(CARD_RESERVED_NAMES).map(([label, value]) => (
                                <Mentions.Option key={value} value={value.slice(1)}>
                                    {label}
                                </Mentions.Option>
                            ))}
                        </Mentions>
                    </Form.Item>
                    <Form.Item
                        label={"Název"}
                        name={"label"}
                        rules={[{required: true}]}
                    >
                        <Mentions
                            split={''}
                            placeholder={"název nebo # pro zobrazení"}
                            prefix={'#'}
                        >
                            {this.getService().getPresenterList().map(({value, label}) => (
                                <Mentions.Option key={value} value={value}>
                                    {label}
                                </Mentions.Option>
                            ))}
                        </Mentions>
                    </Form.Item>
                    <Form.Item
                        label={"Cesta"}
                        name={"route"}
                        rules={[{required: true}]}
                    >
                        <RoutePicker identifier={contentType.name}/>
                    </Form.Item>
                    <Form.Item
                        label={"Akce"}
                        name={"action"}
                    >
                        <ActionPicker contentTypeUuid={contentType.uuid}/>
                    </Form.Item>
                </Form>
                <Button icon={<RightOutlined/>} onClick={() => this.confirm(false)} type={"primary"}>
                    Následující krok
                </Button>
            </Row>
        )
    }
}

const mapStateToProps = (state: IAppState) => {
    return {
        findOneByFullClassName: (className: string) => selectors.services.findOneByFullClassName(state, className)
    }
}

export default connect(mapStateToProps)(CardProperties)