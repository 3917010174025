import React from "react"
import IBaseProps from "model/interface/IBaseProps";
import selectors from "../../../redux/selectors";
import {connect, RootStateOrAny} from "react-redux";
import Error404 from "views/app-views/dynamic-router/Error404";
import IRestServiceFilters from "../../../model/interface/api/IRestServiceFilters";
import {ISetupState} from "../../../redux/reducers/Setup";
import IUser from "../../../model/interface/security/IUser";
import {Tabs} from "antd";
import IViewStateStructure from "../../../model/interface/dataStorage/view/settings/IViewStateStructure";
import IViewSettings from "../../../model/interface/dataStorage/view/IViewSettings";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IView from "../../../model/interface/dataStorage/IView";
import ViewUnit from "./ViewUnit";
import ViewTypesService from "../../../model/service/dataStorage/ViewTypesService";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import IViewPermissions from "../../../model/interface/dataStorage/view/IViewPermissions";

const {TabPane} = Tabs;

interface IState {
    loading: boolean
    state: IViewStateStructure
    showSettings: boolean
    showBaseSettings: boolean
    userSettings?: IViewSettings
    baseSettings?: IViewSettings
    permissions: IViewPermissions
}

interface IProps extends IBaseProps {
    uuid: string,
    resource?: IView
    findOneByUuid: (uuid: string) => IView
    filters?: IRestServiceFilters
    user: IUser
    preview?: boolean,
    standalone?: boolean,
    allowSettings?: boolean,
    allowExport?: boolean,
    title?: string,
    titleLevel?: 1 | 2 | 3 | 4 | 5,
    findContentTypeByUuid: (uuid: string) => IContentType,
    onFinishAction?: (result?: IActionResult) => Promise<void>
    reload?: boolean
}

class View extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps> | IProps) {
        super(props);
        this.state = {
            loading: true,
            showSettings: false,
            showBaseSettings: false,
            state: {},
            permissions: {}
        }
    }

    componentDidMount() {

    }

    getView() {
        return this.props.resource ? this.props.resource : this.props.findOneByUuid(this.props.uuid);
    }

    render() {
        const {filters} = this.props
        const resource = this.getView()

        const unitProps = {...this.props, view: resource, filters: {...filters, ...resource?.filters}}
        return (
            <div>
                {resource ? (resource.units.filter(u => u.active).length > 1 ?
                        <Tabs defaultActiveKey={resource.defaultUnit} className={'overflow-reset'}>
                            {resource.units.filter(u => u.active).sort((a, b) => a.weight - b.weight).map(unit => (
                                <TabPane
                                    tab={<div>{ViewTypesService.get(unit.type).icon}{' ' + unit.label}</div>}
                                    key={unit.uuid}
                                >
                                    <ViewUnit {...{...unitProps, resource: unit}} standalone={false}/>
                                </TabPane>
                            ))}
                        </Tabs> : <div>
                            <ViewUnit {...{...unitProps, resource: resource.units[0]}}/>
                        </div>
                ) : (
                    <Error404/>
                )}
            </div>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        findOneByUuid: (uuid: string) => selectors.views.findOneBy(state, 'uuid', uuid),
        findContentTypeByUuid: (uuid: string): IContentType => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        user
    }
}

export default connect(mapStateToProps)(View)
