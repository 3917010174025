import React from "react";
import {Badge, Col, message, Modal, Row, Tooltip} from "antd";
import {
    CheckOutlined, ClockCircleOutlined,
    DeleteOutlined,
    EyeOutlined,
    FolderOpenOutlined,
    RollbackOutlined,
    StopOutlined
} from "@ant-design/icons";
import IReport, {ReportState} from "../../../../../model/interface/company/workload/IReport";
import ReportsService from "../../../../../model/service/company/workload/ReportsService";
import Button from "../../../../shared/button/Button";
import IBaseProps from "../../../../../model/interface/IBaseProps";
import LocaleText from "../../../settings/dictionary/LocaleText";

interface IProps extends IBaseProps {
    report: IReport
    onChange?: (report: IReport) => void
    canDelete?: boolean
    canShow?: boolean
    canReturn?: boolean
    askChangeStateConfirmation?: boolean
}

interface IState {
    deleting: boolean
    changingState?: ReportState
}

class Actions extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props)
        this.state = {
            deleting: false
        }
    }

    static defaultProps = {
        canDelete: true,
        canShow: true,
        canReturn: true,
        askChangeStateConfirmation: true
    }

    changeState = (report: IReport, state: ReportState) => {
        const {askChangeStateConfirmation} = this.props
        askChangeStateConfirmation ? Modal.confirm({
            title: "Opravdu provést?",
            onOk: () => {
                this.updateState(state, report);
            }
        }) : this.updateState(state, report)
    }

    updateState(state: ReportState, report: IReport) {
        const {onChange} = this.props
        this.setState({changingState: state})
        ReportsService.resourceUpdate(report.uuid, {state}).then(result => {
            message.success('Úspěšně upraveno').then()
            this.setState({changingState: undefined})
            onChange?.(result)
        })
    }

    delete = (report: IReport) => {
        const {onChange} = this.props
        Modal.confirm({
            title: "Opravdu provést?",
            onOk: () => {
                this.setState({deleting: true})
                ReportsService.resourceDelete(report.uuid).then(() => {
                    message.success('Úspěšně smazáno').then()
                    this.setState({deleting: false})
                    onChange?.(report)
                })
            }
        })
    }

    show(report: IReport) {
        const {history} = this.props
        history.push('/app/company/workload/month/' + report.id)
    }

    render() {
        const {report, canShow, canDelete, canReturn} = this.props
        const {changingState, deleting} = this.state

        return <Row justify={'end'} wrap={false} gutter={[6, 6]}>
            {canShow && report._permissions!['show'] && (
                <Col>
                    <Tooltip title="Zobrazit">
                        <Button onClick={() => this.show(report)} type="info" icon={<EyeOutlined/>}
                                size="small"/>
                    </Tooltip>
                </Col>
            )}

            {report._permissions!['archive'] && (
                <Col>
                    <Tooltip title="Archivovat výkaz">
                        <Button onClick={() => this.changeState(report, "archived")} type={'special'}
                                icon={<FolderOpenOutlined/>} size="small" loading={changingState === 'archived'}/>
                    </Tooltip>
                </Col>
            )}
            {report._permissions!.approvalWaiting ? (
                <Col>
                    <Tooltip title={<LocaleText code={'company.workload.plan.approval.waiting'} fallback={'Čekání na schválení ostatních před vámi. Budete informováni!'}/>}>
                        <Badge count={<ClockCircleOutlined/>}>
                            <Button disabled={true} type="success" icon={<CheckOutlined/>}
                                    size="small" onClick={() => this.changeState(report, "approved")}/>
                        </Badge>
                    </Tooltip>
                </Col>
            ) : report._permissions!.approvedByYou ? (
                <Col>
                    <Tooltip title={<LocaleText code={'company.workload.plan.approval.waiting'} fallback={'Vy jste již schválili, ještě musí schválit ostatní.'}/>}>
                        <Badge count={<ClockCircleOutlined style={{ color: '#f5222d' }} />}>
                            <Button disabled={true} type="success" icon={<CheckOutlined/>}
                                    size="small" onClick={() => this.changeState(report, "approved")}/>
                        </Badge>
                    </Tooltip>
                </Col>
            ) : (<>
                {(report._permissions!['approve'] || report._permissions!['approveOwn']) && (
                    <Col>
                        <Tooltip title="Schválit">
                            <Button onClick={() => this.changeState(report, "approved")} type="success"
                                    icon={<CheckOutlined/>} size="small" loading={changingState === 'approved'}/>
                        </Tooltip>
                    </Col>
                )}
                {canReturn && report._permissions!['return'] && (
                    <Col>
                        <Tooltip title="Vrátit k úpravě">
                            <Button onClick={() => this.changeState(report, "returned")} type={'orange'}
                                    icon={<RollbackOutlined/>} size="small" loading={changingState === 'returned'}/>
                        </Tooltip>
                    </Col>
                )}
                {report._permissions!['reject'] && (
                    <Col>
                        <Tooltip title="Zamítnout výkaz">
                            <Button onClick={() => this.changeState(report, "rejected")} type={'danger'}
                                    icon={<StopOutlined/>} size="small" loading={changingState === 'rejected'}/>
                        </Tooltip>
                    </Col>
                )}</>)}
            {canDelete && report._permissions!['delete'] && (
                <Col>
                    <Tooltip title="Smazat">
                        <Button onClick={() => this.delete(report)} type="default" danger
                                icon={<DeleteOutlined/>} size="small" loading={deleting}/>
                    </Tooltip>
                </Col>
            )}
        </Row>
    }
}

export default Actions