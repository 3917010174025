import React from "react"
import {Avatar, Button, Form, Select, Tooltip} from "antd";
import ViewTypesService from "../../../../model/service/dataStorage/ViewTypesService";
import {IViewSetStepProps} from "./ViewConfiguration";
import IViewUnit from "../../../../model/interface/dataStorage/IViewUnit";
import ViewUnitForm from "./unit/ViewUnitForm"
import {CloseOutlined, EditOutlined, EyeInvisibleOutlined, EyeOutlined} from "@ant-design/icons";
import Utils from "../../../../utils";
import DragSortingTable from "../../../shared/Table/DragSortingTable";

interface IState {
    view?: IViewUnit
    showModal: boolean
}

class ViewUnits extends React.Component<IViewSetStepProps, IState> {
    constructor(props: IViewSetStepProps) {
        super(props);
        this.state = {
            showModal: false
        }
    }

    componentDidMount() {

    }

    onSave = (viewUnit?: IViewUnit) => {
        this.setState({showModal: false, view: undefined})
        if (viewUnit) {
            const {resource} = this.props
            const units = [...resource.units]
            this.props.onChange({units: Utils.arrayAddOrUpdateWhere(units, {uuid: viewUnit.uuid}, viewUnit)})
        }
    }

    cancel() {
        this.setState({view: undefined})
    }

    onAdd = () => {
        this.setState({showModal: true})
    }

    onEdit = (viewUnit: IViewUnit) => {
        this.setState({view: viewUnit, showModal: true})
    }

    onRemove = (viewUnit: IViewUnit) => {
        const {resource} = this.props
        const units = [...resource.units]
        this.props.onChange({units: units.filter(unit => unit.uuid !== viewUnit.uuid)})
    }

    onDefaultUnitChange = (value?: string) => {
        this.props.onChange({defaultUnit: value})
    }

    onActiveChange = (viewUnit: IViewUnit, active: boolean) => {
        const {resource} = this.props
        const units = [...resource.units]
        const index = units.findIndex(unit => unit.uuid === viewUnit.uuid)
        if (index > -1){
            viewUnit.active = active
            units[index] = viewUnit
        }
        this.props.onChange({units})
    }

    onSort = (data: IViewUnit[]) => {
        this.props.onChange({units: data.map((row, index) => ({...row, weight: index}))})
    }

    render() {
        const {resource} = this.props
        const {view, showModal} = this.state

        return (
            <>
                {showModal &&
                    <ViewUnitForm view={resource} onSave={this.onSave} resource={view!} history={this.props.history}
                                  match={this.props.match}/>}
                <Button onClick={this.onAdd}>Přidat sekci</Button>
                <DragSortingTable
                    onMove={this.onSort}
                    dataSource={resource.units}
                    rowKey={row => row.uuid}
                    columns={[
                        {title: 'Náźev', dataIndex: 'label'},
                        {
                          title: 'Typ', dataIndex: 'type', render: (text, record) => (
                              <div>
                                  <Avatar className={'bg-success'}
                                          icon={ViewTypesService.get(record.type).icon}/>
                                  {' ' + text}
                              </div>
                          )
                        },
                        {
                          title: '', render: (text, record) => (
                              <div className="text-right d-flex justify-content-end">
                                  <Tooltip title={"Upravit"}>
                                      <Button type="link" onClick={() => this.onEdit(record)}
                                              className="mr-2"
                                              icon={<EditOutlined/>} size="small"/>
                                  </Tooltip>
                                  <Tooltip title={record.active ? 'skrýt' : 'odkrýt' }>
                                      <Button type={"link"} size={"small"} className={"p-0 " + (record.active ? "" : " text-gray-light")}
                                              shape={"circle"}
                                              onClick={() => this.onActiveChange(record, !record.active)}>
                                          {record.active ? <EyeOutlined/> : <EyeInvisibleOutlined/>}
                                      </Button>
                                  </Tooltip>
                                  <Tooltip title={"Odstranit"}>
                                      <Button danger type="link" icon={<CloseOutlined/>}
                                              size="small"
                                              onClick={() => this.onRemove(record)}/>
                                  </Tooltip>
                              </div>
                          )
                        }
                    ]}
                />

                <Form.Item label={'Výchozí aktivní sekce'} className={'mt-2'}>
                    <Select allowClear value={resource.defaultUnit} onChange={this.onDefaultUnitChange}>
                        {resource.units.map(unit => (
                            <Select.Option value={unit.uuid} key={unit.uuid}>{unit.label}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Button disabled={resource.units.filter(u => u.active).length < 1} type={"primary"} onClick={() => this.props.setStep()}>
                    Následující krok
                </Button>
                <Button onClick={() => this.props.setStep(true)}>Předchozí krok</Button>
            </>
        )
    }
}

export default ViewUnits